import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Grid } from '@mui/material'
import Theme from '@refera/ui-core'
import { Button, Datagrid, Dialog, Loader } from '@refera/ui-web'
import {
  FilterSearch as FilterIcon,
  ReceiptText,
  Folder as FolderIcon,
  Danger as DangerIcon,
  Folder2,
} from '@refera/ui-icons'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'

import Filters from './components/Filters'
import HeaderTitle from '_/components/header-title'
import PaymentConfirmation from './paymentConfirmation'
import FloatingMenu from './components/FloatingMenu/FloatingMenu'

import { Columns, handleFormatFilter } from './utils/constants'
import { FINANCE_ROUTES, ROUTES } from '_/utils/constants'

import { getBudgetInstallments } from '_/modules/finance/actions'
import { getAgenciesSimple } from '_/modules/agency/actions'
import { getCompaniesSimple } from '_/modules/company/actions'
import {
  budgetInstallmentsLoadingSelector,
  budgetInstallmentsSelector,
  getBudgetInstallmentsFilterSelector,
  // getBudgetInstallmentsIsFilterDirtySelector,
} from '_/modules/finance/selectors'
import { agenciesSimpleSelector } from '_/modules/agency/selectors'
import { getCompaniesSimpleSelector } from '_/modules/company/selector'

import { getToken } from '_/utils/token'
import axios from 'axios'

import useStyles from './styles'
import { API_URL } from '_/config/environment'
import { useToast } from '_/hooks/use-toast'
import { formatErrorMessage } from '../utils/FormatErrorMessage'
import moment from 'moment'
import humps from 'humps'

const defaultInfoDialog = {
  isOpen: false,
  icon: '',
  type: '',
  subject: '',
  description: '',
}

const COLUMNS_TO_REMOVE = ['condolivreId', 'condolivreStatus', 'condolivreDatetime']

const ManageInstallments = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const installments = useSelector(budgetInstallmentsSelector)
  const [toggleMenu, setToggleMenu] = useState('default')
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedPayments, setSelectedPayments] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [infoDialog, setInfoDialog] = useState(defaultInfoDialog)
  const [floatingMenuOpen, setFloatingMenuOpen] = useState(false)
  const filters = useSelector(getBudgetInstallmentsFilterSelector)
  // const isFilterDirty = useSelector(getBudgetInstallmentsIsFilterDirtySelector)
  const [orderBy, setOrderBy] = useState('')
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const agenciesSimple = useSelector(agenciesSimpleSelector) || []
  const companies = useSelector(getCompaniesSimpleSelector) || []

  const { showToast } = useToast()

  const isLoadingBudgetInstallments = useSelector(budgetInstallmentsLoadingSelector)
  const [isLoadingDownloadSpreadsheet, setIsLoadingDownloadSpreadsheet] = useState(false)

  const hasActiveBatch = useMemo(() => {
    return selectedPayments.some(payment => payment.activeBatch && payment.status !== 'error')
  }, [selectedPayments])

  const installmentsColumns = useMemo(() => {
    return Columns({ styles }).filter(column => !COLUMNS_TO_REMOVE.includes(column.field))
  }, [])

  const handleGetInstallments = useCallback(() => {
    const params = handleFormatFilter(filters)
    const decamelizedOrderBy = orderBy ? humps.decamelize(orderBy) : null

    Promise.resolve(
      dispatch(
        getBudgetInstallments({
          page: page + 1,
          pageSize,
          orderBy: decamelizedOrderBy,
          ...params,
        })
      )
    )
  }, [installments, page, pageSize, orderBy, filters])

  const handleGetAgencies = useCallback(() => {
    if (agenciesSimple.length < 1) dispatch(getAgenciesSimple())
  }, [agenciesSimple])

  const handleGetCompanies = useCallback(() => {
    if (companies.length < 1) {
      dispatch(getCompaniesSimple())
    }
  }, [companies])

  const handleGoToBatchScreen = () => navigate(FINANCE_ROUTES.BATCHES)
  const handleGoToReturnFilesScreen = () => navigate(FINANCE_ROUTES.BANK_RETURN_FILES)
  const handleGoToChargeFilesScreen = () => navigate(FINANCE_ROUTES.CHARGE_FILES)

  const handleSuccessPayment = useCallback(
    (success, message) => {
      if (!success) {
        showToast({
          type: 'error',
          message: formatErrorMessage(message),
        })
        return
      }
      showToast({
        type: 'success',
      })
      setToggleMenu('default')
      setSelectedItems([])
      handleGetInstallments()
    },
    [handleGetInstallments, setToggleMenu, setSelectedItems]
  )

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('createdAt')
        return
      }
      const { field, sort } = order
      sort === 'desc' ? setOrderBy(`-${field}`) : setOrderBy(field)
    },
    [setOrderBy]
  )

  const handleSelectedPayments = useCallback(() => {
    if (selectedItems.length > 0) {
      const newArray = [...selectedPayments?.filter(item => selectedItems.includes(item.id))]

      installments?.results?.map(
        item =>
          selectedItems.includes(item.id) &&
          !selectedPayments.filter(payment => item.id === payment.id)[0] &&
          newArray.push(item)
      )

      setSelectedPayments(newArray)
      setTotalPrice(newArray?.reduce((acc, curr) => acc + curr.providerValue, 0))
      setFloatingMenuOpen(true)
    } else {
      if (floatingMenuOpen) setFloatingMenuOpen(false)
      setTotalPrice(0)
      setSelectedPayments([])
    }
  }, [selectedItems, selectedPayments, installments, floatingMenuOpen])

  useEffect(() => {
    handleSelectedPayments()
  }, [selectedItems])

  useEffect(() => {
    handleGetCompanies()
    handleGetAgencies()
  }, [])

  useEffect(() => {
    handleGetInstallments()
  }, [page, pageSize, orderBy])

  const handleFilters = () => setFilterDrawerOpen(prevState => !prevState)

  const BudgetInstallmentsFilter = useMemo(
    () => (
      <Filters
        handleFilterDrawer={handleFilters}
        isOpen={filterDrawerOpen}
        params={filters}
        open={filterDrawerOpen}
        setPage={setPage}
        handleGetInstallments={handleGetInstallments}
      />
    ),
    [filterDrawerOpen, filters]
  )

  const buttonColor = useMemo(() => {
    return isLoadingBudgetInstallments
      ? Theme.Colors.Grayscale.ThirtyTwo
      : Theme.Colors.Primary.Base
  }, [isLoadingBudgetInstallments])

  const handleGoToConfirm = useCallback(
    menuType => {
      let errorMessage = ''

      if (menuType === 'remove') {
        if (selectedPayments.some(payment => payment.anticipationDate)) {
          errorMessage =
            'Uma ou mais parcelas foram pagas por antecipação, não podendo ter seus pagamentos cancelados por esta tela.'
        }
        if (!selectedPayments.some(payment => payment.status === 'paid')) {
          errorMessage = 'Nenhum registro selecionado está pago.'
        }
        setSelectedIds(selectedPayments.filter(item => item.status === 'paid').map(item => item.id))
      } else {
        if (hasActiveBatch) {
          errorMessage =
            'Não é possível prosseguir com a ação, pois as parcelas a seguir pertecem à lotes.'
        }
        if (!selectedPayments.some(payment => payment.status === 'pending')) {
          errorMessage = 'Nenhum registro selecionado está pendente.'
        }

        setSelectedIds(
          selectedPayments.filter(item => item.status === 'pending').map(item => item.id)
        )
      }

      if (errorMessage) {
        setInfoDialog({
          isOpen: true,
          icon: DangerIcon,
          type: 'error',
          subject: 'Erro',
          description: errorMessage,
        })
        return
      }
      setToggleMenu(menuType)
    },
    [hasActiveBatch, selectedPayments]
  )

  const handleCellClick = useCallback(params => {
    if (params.field === '__check__') return
    if (params.field === 'serviceOrderId') {
      window.open(
        `${ROUTES.SERVICE_ORDER}/${params.row.serviceOrderId}`,
        '_blank',
        'noopener,noreferrer'
      )
      return
    }
    if (params.field === 'lastBatchId') {
      window.open(
        `${FINANCE_ROUTES.BATCHES}/${params.row.lastBatchId}`,
        '_blank',
        'noopener,noreferrer'
      )
      return
    }
    window.open(
      `${FINANCE_ROUTES.MANAGE_INSTALLMENTS}/parcela/${params.row.id}`,
      '_blank',
      'noopener,noreferrer'
    )
  }, [])

  const handleDownloadSpreadsheet = useCallback(async () => {
    try {
      setIsLoadingDownloadSpreadsheet(() => true)
      const headers = getToken()
      const params = { installments_ids: selectedItems }
      const paramString = new URLSearchParams(params).toString()

      const response = await axios(
        `${API_URL}/finance/budget-installment/download-spreadsheet/?${paramString}`,
        { ...headers, responseType: 'blob' }
      )

      const now = moment().format('YYYYMMDDHHmmss')
      const fileName = `Parcelas_de_pgto_${now}.xlsx`

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const downloadLink = document.createElement('a')

      downloadLink.href = url
      downloadLink.setAttribute('download', fileName)
      document.body.appendChild(downloadLink)
      downloadLink.click()

      setIsLoadingDownloadSpreadsheet(() => false)
    } catch (error) {
      setIsLoadingDownloadSpreadsheet(() => false)
      showToast({
        type: 'error',
        message: formatErrorMessage(error),
      })
    }
  }, [selectedItems])

  return (
    <Grid>
      {BudgetInstallmentsFilter}
      {toggleMenu === 'default' ? (
        <>
          <HeaderTitle title="Pagamento de Chamados">
            <Loader hasBackdrop open={isLoadingDownloadSpreadsheet} />
            <Grid className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                startIcon={<Folder2 color={Theme.Colors.Primary.Base} />}
                onClick={handleGoToChargeFilesScreen}
              >
                Arquivos de cobrança
              </Button>
              <Button
                variant="secondary"
                startIcon={<FolderIcon color={Theme.Colors.Primary.Base} />}
                onClick={handleGoToReturnFilesScreen}
              >
                Arquivos de retorno
              </Button>
              <Button
                variant="secondary"
                startIcon={<ReceiptText color={Theme.Colors.Primary.Base} />}
                onClick={handleGoToBatchScreen}
              >
                Lotes de pagamento
              </Button>
              <Button
                variant="ghost"
                startIcon={<FilterIcon color={buttonColor} />}
                onClick={handleFilters}
                disabled={isLoadingBudgetInstallments}
              >
                Filtros
              </Button>
            </Grid>
          </HeaderTitle>
          <Grid className={styles.tableContainer}>
            <Grid className={styles.mainTable}>
              <Datagrid
                loading={isLoadingBudgetInstallments}
                rows={installments?.results?.length ? installments?.results : []}
                columns={installmentsColumns}
                paginationMode="server"
                onCellClick={handleCellClick}
                onPageChange={setPage}
                page={page}
                onPageSizeChange={setPageSize}
                pageSize={pageSize}
                rowCount={installments?.count}
                onSortModelChange={handleOrderBy}
                checkboxSelection
                disableSelectionOnClick
                // isRowSelectable={params => handleSelect(params?.row)}
                onSelectionModelChange={setSelectedItems}
                keepNonExistentRowsSelected
                selectionModel={selectedItems}
                sx={{
                  fontSize: '1.4rem',
                  '& .MuiDataGrid-cell:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </Grid>
            {floatingMenuOpen && (
              <FloatingMenu
                selected={selectedItems.length}
                price={totalPrice}
                handleGoToConfirm={handleGoToConfirm}
                handleDownloadSpreadsheet={handleDownloadSpreadsheet}
              />
            )}
          </Grid>
        </>
      ) : (
        <PaymentConfirmation
          toggleMenu={() => setToggleMenu('default')}
          menuType={toggleMenu}
          selectedIds={selectedIds}
          items={selectedPayments.filter(payment => selectedIds.includes(payment.id))}
          handleSuccessPayment={handleSuccessPayment}
        />
      )}
      {infoDialog.isOpen && (
        <Dialog
          open={infoDialog.isOpen}
          icon={infoDialog.icon}
          type={infoDialog.type}
          subject={infoDialog.subject}
          description={infoDialog.description}
          onApprove={() => setInfoDialog(defaultInfoDialog)}
        />
      )}
    </Grid>
  )
}

export default ManageInstallments
